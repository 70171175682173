import React, { Component } from 'react';
import AgoraRTC from 'agora-rtc-sdk';
import './StreamHost.css';
import config from '../config';
// import axios from 'axios';

class StreamHost extends Component {
    role = 'host';

    localStream = null;
    mediaStream;

    closeStream() {
        this.localStream.close();
    }

    async startStream(facingMode) {

        // const response = await axios.get(`${config.apiPath}/generateToken/${this.role}`);

        // console.log(response);

        let client = AgoraRTC.createClient({
            mode: "live",
            codec: "vp8",
        });

        if (this.localStream) {

            client.unpublish(this.localStream, (err) => {
                console.log("Error: ", err);
            });
            // this.localStream.close();
        }

        const options = {
            audio: true,
            video: {
                facingMode,
            },
        };

        try {
            if (this.mediaStream) {
                const tracks = this.mediaStream.getTracks();
                tracks.forEach(track => track.stop());
            }
            this.mediaStream = await navigator.mediaDevices.getUserMedia(options);
        } catch (e) {
            alert(e);
            return;
        }

        console.log(this.mediaStream.getVideoTracks())
        const videoSource = this.mediaStream.getVideoTracks()[0];
        const audioSource = this.mediaStream.getAudioTracks()[0];

        client.init(config.token);
        client.setClientRole(this.role);
        client.join(config.token, config.channel, null, (uid) => {
            this.localStream = AgoraRTC.createStream({
                audio: true,
                video: true,
                videoSource: videoSource,
                audioSource: audioSource
            });

            this.localStream.init(() => {
                this.removeVideoStream('video');

                this.localStream.play("video");
                client.publish(this.localStream, (err) => {
                    console.log("Error: ", err);
                });
            }, (err) => {
                console.log("Error: ", err);
            });
        }, (err) => {
            console.log("Error: ", err);
        });
    }

    componentWillUnmount() {
        this.closeStream();
    }

    removeVideoStream(elementId) {
        let remoteDiv = document.getElementById(elementId);
        remoteDiv.innerHTML = '';
    }


    render() {
        return (
            <section>
                <div>
                    <button onClick={() => this.startStream('user')}>Front camera</button>
                    <button onClick={() => this.startStream('environment')}>Back camera</button>
                </div>
                <div>
                    <div className="go-live">
                        <button onClick={() => this.startStream()}>Go Live</button>
                    </div>
                    <div id="video"></div>
                </div>
            </section>
        )
    }
}

export default StreamHost;