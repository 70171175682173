import React from 'react';
import { withRouter } from "react-router-dom";
import ReactPlayer from 'react-player'
import config from '../config';

class VideoPlayer extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            stream: false,
            url: ``,
            playing: false,
            autoPlay: false
        }
    }

    componentDidMount() {

        this.setState({
            stream: true,
            url: `${config.rtmpPath}/live/${this.props.match.params.stream_key}/index.m3u8`
        }, () => {
            // this.player = videojs(this.videoNode, this.state.videoJsOptions, function onPlayerReady() {
            //     console.log('onPlayerReady', this)
            // });


        });
    }

 
    onReadyHandler(item) {
        console.log(item);
        this.setState({
            autoPlay: true,
            playing: true
        })
    }
    // play() {
    //     this.setState({
    //         autoPlay: true,
    //         playing: true
    //     })
    // }

    render() {
        return (
            <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-10 col-lg-8 mx-auto mt-5">

                    <div >
                        <ReactPlayer
                            url={this.state.url}
                            className='react-player'
                            controls={true}
                            playing={this.state.playing}
                            autoPlay={this.state.autoPlay}
                            width='80%'
                            height='80%'
                            onReady={(i) => this.onReadyHandler(i)}
                        />
                        {/* <video ref={node => this.videoNode = node} className="video-js vjs-big-play-centered"/> */}
                    </div>

                </div>
            </div>
        )
    }
}

export default withRouter(VideoPlayer)